import cn from 'classnames';
import { FC } from 'react';

import { Hero, HorizontalAlignment, VerticalAlignment } from '@components/common/types/Hero';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Link from '@components/ui/Link';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { getContentfulImgSet, renderResponsiveImage } from '@lib/image';

import style from './HeroBanner.module.scss';

interface Props extends Hero {
  className?: string;
  darkenBackground?: boolean;
  buttonBackgroundColor?: string;
  ctaTextHexColor?: string;
  headlineHexColor?: string;
  titleHexColor?: string;
  priority?: boolean;
}

const HeroBanner: FC<Props> = ({
  className,
  h2,
  title,
  buttonText,
  ctaUrl,
  banner: desktopBanner,
  mobileBanner,
  darkenBackground = false,
  internalName,
  buttonBackgroundColor,
  ctaTextHexColor,
  headlineHexColor,
  titleHexColor,
  horizontalAlignment = HorizontalAlignment.Center,
  mobileHorizontalAlignment = HorizontalAlignment.Center,
  verticalAlignment = VerticalAlignment.Center,
  mobileVerticalAlignment = VerticalAlignment.Bottom,
}) => {
  // this happens if a module is in draft mode, we just get back a sys link
  // so rather than render an empty block, return out null
  if (!internalName || !ctaUrl) {
    return null;
  }

  return (
    <Section mobileNoPadding>
      <Link href={ctaUrl} prefetch={false}>
        <div
          role="banner"
          aria-label="Hero"
          className={cn(style.root, className, {
            [style.top]: mobileVerticalAlignment === VerticalAlignment.Top,
            [style.bottom]: mobileVerticalAlignment === VerticalAlignment.Bottom,
            [style.mdTop]: verticalAlignment === VerticalAlignment.Top,
            [style.mdBottom]: verticalAlignment === VerticalAlignment.Bottom,
          })}
        >
          {renderResponsiveImage(getContentfulImgSet(desktopBanner, mobileBanner), {
            className: cn(style.bgImage, { [style.darken]: darkenBackground }),
            pictureClass: style.pictureClass,
            priority: true,
          })}
          <div
            className={cn(style.wrapper, {
              [style.wrapperLeft]: mobileHorizontalAlignment === HorizontalAlignment.Left,
              [style.wrapperRight]: mobileHorizontalAlignment === HorizontalAlignment.Right,
              [style.wrapperMdLeft]: horizontalAlignment === HorizontalAlignment.Left,
              [style.wrapperMdRight]: horizontalAlignment === HorizontalAlignment.Right,
            })}
          >
            {h2 && (
              <Text
                variant="heading-3"
                color={headlineHexColor || 'var(--white)'}
                className="mb-2 md:mb-1"
                asElement="h2"
              >
                {h2}
              </Text>
            )}
            {title && (
              <Text variant="heading-1" color={titleHexColor || 'var(--white)'} asElement={h2 ? 'h3' : 'h2'}>
                {title}
              </Text>
            )}
            {buttonText && (
              <Button
                variant="cta"
                aria-label={buttonText}
                className={style.button}
                size="md"
                {...(buttonBackgroundColor && {
                  style: {
                    backgroundColor: buttonBackgroundColor,
                  },
                })}
              >
                <Text variant="text-2" weight="semibold" color={ctaTextHexColor}>
                  {buttonText}
                </Text>
              </Button>
            )}
          </div>
        </div>
      </Link>
    </Section>
  );
};

export default Renderer({ name: 'moduleHero' })(HeroBanner);
